<template>
  <div class="desc">
    <div class="descDoc">
      <div>
        <div><h1>- 出口代理 -</h1></div>
        <div>
          <span
            >深圳市捷汇锐进出口公司经深圳海关和国家商务部批准，工商局依法登记注册进出口公司，拥有多年专业的进出口报关经验，为企业提供深圳一手的进出口代理服务。捷汇锐有专业报关团队，代理深圳盐田、蛇口、皇岗、机场UPS\FEDEX\TNT\DHL进出口报关报检服务，深圳一手报关值得信赖。</span
          >
        </div>
      </div>
    </div>
    <div class="process">
      <div><h1>- 运作流程 -</h1></div>
      <img
        src="../../assets/images/contentImgs/process.svg"
        alt="出口代理"
      />
    </div>
    <div class="servelist">
      <div><h1>- 优势 -</h1></div>
      <div>
        <div>
          <img src="../../assets/images/contentImgs/world_export.png" alt="货运" />
        </div>
        <div>
          <ul>
            <li>全程一站式服务</li>
            <li>专业化团队，从事报关行业十余年</li>
            <li>质量有保障，提升企业动作效率</li>
            <li>高性价比，专业人员操作，系统化操作，为企业降低成本</li>
            <li>优质服务，一对一的服务专员</li>
            <li>优势申报产品:电子数码产品、服装、家具、机器设备</li>
            <li>服务口岸:盐田港、蛇口港、机场、皇岗、文锦渡、南沙港、黄埔港</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
@import url("../../assets/css/agentcompnt.less");
.descDoc{
  background: #94a1ac;
  color: white !important;
}
li{
  line-height: 30px !important;
  font-size: 15px;
}
</style>